<script lang="ts" setup>
const contentKey = 'homepage'
const { searchContent } = useContent()
const { data: content, pending: loading } = await useAsyncData(contentKey, async () => {
  return await searchContent({ keys: [contentKey] })
})
const { setSeoHomepage } = useSEO()
const { reportPageView } = useTagManager()
const { setupUrls } = useAlternativePageUrls()
const { getURL } = useURL()

useCache('homepage')

setupUrls((locale) => getURL({ name: 'index' }, locale))

reportPageView('home')
setSeoHomepage()
const slotValue = computed(() => content.value?.[contentKey])
</script>
<template>
  <ContentPage
    v-if="slotValue"
    :content="slotValue"
    :content-key="contentKey"
    :loading="loading"
    class="asf-homepage"
    v-e2e="'homepage-content-area'"
  >
    <template #content-product-carousel>
      <AsfWrapper tag="section">
        <AppLastVisitedProducts />
      </AsfWrapper>
    </template>
  </ContentPage>
</template>
<style lang="postcss">
@import 'assets/css/pages/homepage.css';
</style>
